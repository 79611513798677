import React, {useState } from 'react'
import { useExitIntent } from 'use-exit-intent';
import NewsletterSignup from './newsletter-signup';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Cookies from 'js-cookie';
function NewsletterSignupModal() {
    const [show, setShow] = useState(false);
    const { unsubscribe, registerHandler } = useExitIntent({
        "cookie": {
          "daysToExpire": 30,
          "key": "use-exit-intent"
        },
        "desktop": {
          "triggerOnIdle": true,
          "useBeforeUnload": true,
          "triggerOnMouseLeave": true,
          "delayInSecondsToTrigger": 30
        },
        "mobile": {
          "triggerOnIdle": true,
          "delayInSecondsToTrigger": 30
        }
    });
    const handleUnsubscription = () => {
        setShow(false);
        unsubscribe();
    } 
    
    registerHandler({
        id: 'subscriptionPopup',
        handler: () => { 
            setShow(true);
        },
    });


  return (
    <>
    {show && (
        <div id="subscribe-popup" tabIndex="-1" className="fixed flex flex-col items-center justify-center top-0 left-0 right-0 z-[100] w-full  bg-black bg-opacity-75 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full">
            <div className="flex flex-col relative px-4 w-full max-w-[48rem] xl:max-w-[64rem] md:mx-auto h-auto">
                <button onClick={()=> handleUnsubscription()} type="button" className="-mb-10 text-2xl mr-2 z-20 relative text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg  p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="subscribe-popup">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPCHA_SITE_KEY}
                        language="en"
                        scriptProps={{
                            async: false,
                            defer: false,
                            appendTo: 'head'
                        }}
                    >
                    <NewsletterSignup />
                </GoogleReCaptchaProvider>
            </div>
        </div>
    )}
    </>
  )
}

export default NewsletterSignupModal