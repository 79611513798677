import '../styles/globals.scss';
import { fetchAPI } from '../lib/api';
import { GlobalContextProvider } from '../lib/global-context';
import Consent from '../components/consent';
import NewsletterSignupModal from '../components/newsletter-signup-modal';
import PushNotification from '../components/push-notification';

function MyApp({ Component, pageProps }) {
    const { global } = pageProps;

    return (
        <>
            <GlobalContextProvider init={global}>
                <NewsletterSignupModal />
                <PushNotification />
                <Consent></Consent>
                <Component {...pageProps} />
            </GlobalContextProvider>
        </>
    );
}
// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (ctx) => {
    try {
        // Fetch global site settings from Strapi
        const globalRes = await fetchAPI('/global', {
            populate: 'deep,5',
            locale: ctx.router.locale
        });

        const cookiesPopup = await fetchAPI('/cookie-popups', { locale: ctx.router.locale });
        globalRes.data.attributes.cookiesPopup = { settings: cookiesPopup.data };

        // get cookies
        const cookiesCategories = await fetchAPI('/cookie-categories', { locale: ctx.router.locale });
        globalRes.data.attributes.cookieCategories = { settings: cookiesCategories.data };

        // get languages
        const locales = await fetchAPI('/i18n/locales', {
            populate: 'deep,3'
        });

        globalRes.data.attributes.languages = locales.map((l) => {
            return { isDefault: l.isDefault, name: l.name, code: l.code };
        });

        // Pass the data to our page via props
        return {
            pageProps: { global: globalRes.data.attributes },
            revalidate: Number(process.env.NEXT_PUBLIC_PAGE_REVALIDATION) || 100
        };
    } catch (e) {
        console.log(e);
        return { notFound: true };
    }
};
// export function reportWebVitals(metric) {
//     console.log(metric);
// }
export default MyApp;
