import { useGlobalContext } from '../lib/global-context';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';

const CreateConsentData = (categories, value = 'default') => {
    const data = {};
    categories?.map((cat) => {
        if (value === 'default') {
            value = true;
        }
        data[cat.attributes.name.toLowerCase()] = value;
    });
    return data;
};

export default function Consent() {
    const { state, dispatch } = useGlobalContext();
    const popup = state.cookiesPopup;
    const cookieCategories = state.cookieCategories?.settings;

    const [showPref, setShowPref] = useState(false);
    const [showCookieList, setShowCookieList] = useState('');
    const [consentData, setConsentData] = useState(CreateConsentData(cookieCategories));

    useEffect(() => {
        // get data from cookie
        const consentCookie = Cookies.get('consent');
        if (consentCookie) {
            setConsentData(JSON.parse(consentCookie));
            setShowPref(false);
            dispatch({
                type: 'TOGGLE_CONSENT',
                payload: false
            });
        } else {
            dispatch({
                type: 'TOGGLE_CONSENT',
                payload: true
            });
        }
    }, [dispatch]);


    const consentSubmit = async (option) => {
        let data = consentData;
        switch (option) {
            case 'DENY_ALL':
                data = CreateConsentData(cookieCategories, false);
                setConsentData(data);

                break;
            case 'ALLOW_ALL':
                data = CreateConsentData(cookieCategories, true);
                setConsentData(data);
                break;
        }


        setCookie(data);

        window.dataLayer.push({
            event: 'consent-updated'
        });

        setShowPref(false);

        dispatch({
            type: 'TOGGLE_CONSENT',
            payload: false
        });
    };

    const setCookie = (data) => {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        Cookies.set('consent', JSON.stringify(data), {
            expires: oneYearFromNow,
            httpOnly: false,
            secure: true,
            samesite: 'None'
        });

    }

    const handleCheckbox = (event, category) => {
        //console.log(event, category);
        consentData[category.toLowerCase()] = event.target.checked;
        setConsentData(consentData);
    };

    return (
        <>
            {state.showConsentBox && (
                <>
                    {!showPref && (
                        <div className="fixed w-full bg-black bg-opacity-90 text-white z-50 bottom-0">
                            <div className="flex flex-col md:flex-row rounded-lg mx-auto max-w-screen-xl  p-4">
                                <div className="flex flex-col  flex-grow">
                                    <h3 className="text-sm">{popup.settings[0].attributes.title}</h3>
                                    <p className="text-xs">{popup.settings[0].attributes.description}</p>
                                    <Link href="/privacy-policy">
                                        <a title="Read the privacy policy" className="cursor-pointer underline text-xs hover:no-underline hover:opacity-50">
                                            Privacy Policy
                                        </a>
                                    </Link>
                                </div>
                                <div className="flex flex-row flex-shrink-0 items-center justify-end p-0 md:pl-4 ">
                                    <button
                                        onClick={() => setShowPref(true)}
                                        className="btn underline md:mr-2 !px-2 md:!px-4"
                                    >
                                        Manage Preferences
                                    </button>
                                    <button
                                        onClick={() => consentSubmit('DENY_ALL')}
                                        className="btn flex-grow-1 action-border !text-white mr-2 update-consent !px-2 md:!px-4"
                                    >
                                        Reject All
                                    </button>
                                    <button
                                        onClick={() => consentSubmit('ALLOW_ALL')}
                                        className="btn trust-border !text-white update-consent !px-2 md:!px-4"
                                    >
                                        Accept All
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showPref && (
                        <div className="fixed w-full bg-black bg-opacity-90 z-[100] grid place-items-center h-screen">
                            <div className="flex flex-col rounded-lg mx-auto max-w-screen-md bg-white p-10">
                                <div className="flex flex-col">
                                    <form id="">
                                        {cookieCategories &&
                                            cookieCategories.map((cookieCat) => {
                                                return (
                                                    <div
                                                        key={`cookie-${cookieCat.id}`}
                                                        className="flex flex-row mb-4"
                                                    >
                                                        <div className="h-5">
                                                            <input
                                                                defaultChecked={
                                                                    cookieCat.attributes.name.toLowerCase() ===
                                                                    'necessary' ||
                                                                    consentData[
                                                                    cookieCat.attributes.name.toLowerCase()
                                                                    ]
                                                                }
                                                                disabled={
                                                                    cookieCat.attributes.name.toLowerCase() ===
                                                                    'necessary'
                                                                }
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                    handleCheckbox(
                                                                        e,
                                                                        cookieCat.attributes.name
                                                                    )
                                                                }
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded-lg border-gray-300 "
                                                                name={cookieCat.attributes.name}
                                                            />
                                                        </div>
                                                        <div className="ml-2 text-sm mr-4 flex-grow">
                                                            <label
                                                                className="text-sm font-semibold"
                                                                htmlFor={cookieCat.attributes.name}
                                                            >
                                                                {cookieCat.attributes.name}
                                                            </label>
                                                            <p>
                                                                <span>
                                                                    {cookieCat.attributes.description}
                                                                </span>
                                                                <br />
                                                                {cookieCat.attributes.cookies.data.length ==
                                                                    0 && (
                                                                        <span className="btn !p-0 !cursor-text">
                                                                            {
                                                                                cookieCat.attributes.cookies.data
                                                                                    .length
                                                                            }{' '}
                                                                            cookies
                                                                        </span>
                                                                    )}
                                                            </p>

                                                            {cookieCat.attributes.cookies.data.length > 0 && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn link"
                                                                        onClick={() =>
                                                                            setShowCookieList(
                                                                                showCookieList ===
                                                                                    cookieCat.attributes.name
                                                                                    ? ''
                                                                                    : cookieCat.attributes
                                                                                        .name
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            cookieCat.attributes.cookies.data
                                                                                .length
                                                                        }{' '}
                                                                        cookies
                                                                    </button>

                                                                    <table
                                                                        className={`w-full relative text-xs transform duration-500 ${showCookieList ==
                                                                                cookieCat.attributes.name
                                                                                ? 'opacity-100 inset-y-0'
                                                                                : 'opacity-0 -inset-y-3/4 hidden'
                                                                            }`}
                                                                    >
                                                                        <tbody className="border-t">
                                                                            {cookieCat.attributes.cookies.data.map(
                                                                                (c) => (
                                                                                    <tr
                                                                                        key={`cookie-detail-${c.id}`}
                                                                                        className="border-b"
                                                                                    >
                                                                                        <td className="px-6 py-4 whitespace-nowrap font-medium">
                                                                                            {
                                                                                                c.attributes
                                                                                                    .name
                                                                                            }
                                                                                        </td>
                                                                                        <td className="font-light px-6 py-4 whitespace-nowrap">
                                                                                            {
                                                                                                c.attributes
                                                                                                    .party
                                                                                            }
                                                                                        </td>
                                                                                        <td className="font-light px-6 py-4 ">
                                                                                            {
                                                                                                c.attributes
                                                                                                    .description
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </form>
                                </div>

                                <div className="flex flex-row flex-shrink-0 items-center justify-end pt-8 pl-4">
                                    <button
                                        onClick={() => setShowPref(false)}
                                        className="btn maturity-border mr-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => consentSubmit('ALLOW_SELECTION')}
                                        className="btn trust-full update-consent"
                                    >
                                        Accept Selection
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
