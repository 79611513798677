import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import * as localForage from 'localforage';
const firebaseConfig = {
    apiKey: 'AIzaSyDCU7t2tf1f3OomtuBQhj5HzHkn5Ne-l1E',
    authDomain: 'arka-robot.firebaseapp.com',
    projectId: 'arka-robot',
    storageBucket: 'arka-robot.appspot.com',
    messagingSenderId: '493754621157',
    appId: '1:493754621157:web:6f4add731115e7be24480c',
    measurementId: 'G-Q0V7Q48JNL'
};

export const app = initializeApp(firebaseConfig);

export const initMessaging = async () => {
    const localToken = await localForage.getItem('fcm_token');

    const permission = await Notification.requestPermission();
    const messaging = getMessaging(app);
    if (permission === 'granted') {
        if (localToken !== null) {
            return true;
        }

        // Get new token from Firebase
        const token = await getToken(messaging, {
            vapidKey: process.env.NEXT_PUBLIC_FB_VAPID
        });

        const request = await fetch('/api/push-subscribe', {
            header: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                token,
                status: 'subscribe'
            })
        });
        await request.json();

        // Set token in our local storage
        if (token) {
            localForage.setItem('fcm_token', token);
            return true;
        }
    } else {
        if (localToken === null) {
            return false;
        }

        const request = await fetch('/api/push-subscribe', {
            header: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                token: localToken,
                status: 'unsubscribe'
            })
        });
        await request.json();

        localForage.removeItem('fcm_token');
        return false;
    }
};
