import React, { useEffect, useCallback } from 'react';
import { app, initMessaging } from '../lib/firebase';
import { ToastContainer, toast } from 'react-toastify';
import { getMessaging } from 'firebase/messaging';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
function PushNotification() {
    const router = useRouter();
    const requestPermissions = useCallback(async () => {
        const status = await initMessaging();
        if (!status) {
            return;
        }

        // Get the push notification message and triggers a toast to display it
        const messaging = getMessaging(app);
        messaging.onMessageHandler = (message) => {
            //console.log('message', message);
            toast(
                <div onClick={() => router.push(message?.data?.url)}>
                    {message?.notification?.image && (
                        <div className="relative w-full aspect-video">
                            <Image
                                src={message.notification.image}
                                layout="fill"
                                className="object-fill"
                                alt={message?.notification?.title}
                            ></Image>
                        </div>
                    )}
                    <h3 className="text-2xl font-semibold text-trust mt-4">{message?.notification?.title}</h3>

                    <p className="py-2">{message?.notification?.body}</p>
                    <Link href={message?.data?.url}>
                        <a className="btn trust-full mb-4">Read more</a>
                    </Link>
                </div>,
                {
                    closeOnClick: false
                }
            );
        };
    }, [router]);

    useEffect(() => {
        requestPermissions();
    }, [requestPermissions]);

    return (
        <>
            <ToastContainer autoClose={100000} />
        </>
    );
}

export default PushNotification;
