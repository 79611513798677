import { useReducer, createContext, useContext } from 'react';

const initialState = {
    galleryStatus: false,
    showConsentBox: false
};

// combine reducer function
const combineReducers = (...reducers) => {
    (state, action) => {
        for (let i = 0; i < reducers.length; i++) {
            state = reducers[i](state, action);
        }
        return state;
    };
};

const actionsList = (state, action) => {
    switch (action.type) {
        case 'GALLERY':
            return { ...state, galleryStatus: action.payload };
        case 'TOGGLE_CONSENT':
            return { ...state, showConsentBox: action.payload };
        default:
            return state;
    }
};

// Store Strapi Global object in context
const GlobalContext = createContext();

export function useGlobalContext() {
    return useContext(GlobalContext);
}

// context provider
const GlobalContextProvider = ({ init, children }) => {
    const initStateData = { ...initialState, ...init };
    const [currentState, dispatch] = useReducer(actionsList, initStateData); // pass more reducers combineReducers(user, blogs, products)
    // overwrite state with the new data we got as global
    const state = { ...currentState, ...init };
    const value = { state, dispatch };

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
